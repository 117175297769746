// hooks/useStatsigExposure.ts

import { useEffect, useRef } from "react";
import { useLayer, useStatsigUser } from "@statsig/react-bindings";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";

const analyticsUtils = new AnalyticsUtils();
export function useStatsigExposure(layerId: string) {
    const exposureLoggedRef = useRef(false);
    const testLayer = useLayer(layerId);
    const user = useStatsigUser();
    useEffect(() => {
        if (!exposureLoggedRef.current && !!testLayer && !!user.user.userID) {
            exposureLoggedRef.current = true;
            analyticsUtils.sendDataDogAction("statsig_exposure", {
                userID: user.user.userID,
                layer: testLayer.name,
                ruleID: testLayer.ruleID,
                allocatedExperimentName: testLayer.__evaluation?.allocated_experiment_name
            });
        }
    }, [testLayer, user.user.userID]);
}
